<template>
  <div class="loginMind">
    <div class="carouselTop">
      <el-carousel class="carouselTop" indicator-position="outside">
        <el-carousel-item class="carouselTop" v-for="i in imgList" :key="i.id">
          <img :src="i.url" class="carouselImg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="loginCore">
      <div class="loginCoreRow">Product Center</div>
      <div class="loginCoreTitle">产品中心</div>
      <div class="loginCorePhoto">
        <div
          class="loginCorePhotoItem"
          @mouseover="overOne(1)"
          @mouseout="outOne(0)"
        >
          <img
            :src="oneJust"
            class="loginCorePhotoItemImg"
            v-if="oneIndex == 0"
          />
          <img
            :src="oneBack"
            class="loginCorePhotoActive"
            v-else-if="oneIndex == 1"
          />
        </div>
        <div
          class="loginCorePhotoMiddle"
          @mouseover="overTow(1)"
          @mouseout="outTow(0)"
        >
          <img
            :src="towJust"
            class="loginCorePhotoItemImg"
            v-if="towIndex == 0"
          />
          <img
            :src="oneBack"
            class="loginCorePhotoActive"
            v-else-if="towIndex == 1"
          />
        </div>
        <div
          class="loginCorePhotoItem"
          @mouseover="overThree(1)"
          @mouseout="outThree(0)"
        >
          <img
            :src="threeJust"
            class="loginCorePhotoItemImg"
            v-if="threeIndex == 0"
          />
          <img
            :src="oneBack"
            class="loginCorePhotoActive"
            v-else-if="threeIndex == 1"
          />
        </div>
      </div>
    </div>
    <div class="loginSolve">
      <img
        src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109171036_fangan.png"
        class="solvebackgroundImg"
      />
      <div class="loginSolveBody">
        <div class="loginSolvetop">Solution</div>
        <div class="loginSolveTitle">解决方案</div>
        <div class="loginSolveMiddle">
            <div class="loginSolveMiddleLeft">
              <div class="loginSolveMiddleRow">
                <div class="loginSolveMiddleItem">全资源整合</div>
              </div>
              <div class="loginSolveMiddleRowOne">
                <div class="loginSolveMiddleItem">全产业融合</div>
              </div>
              <div class="loginSolveMiddleRowTow">
                <div class="loginSolveMiddleItem">全方位服务</div>
              </div>
            </div>
            <div class="loginSolveMiddleImg">
                <img src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109173252_xunhaun.png" class="loginSolveMiddleImg">
            </div>
            <div class="loginSolveMiddleLeft">
              <div class="loginSolveMiddleRowThree">
                <div class="loginSolveMiddleItem">全时代消费</div>
              </div>
              <div class="loginSolveMiddleRowFour">
                <div class="loginSolveMiddleItem">全场景体验</div>
              </div>
              <div class="loginSolveMiddleRowFive">
                <div class="loginSolveMiddleItem">全民化共享</div>
              </div>
            </div>
        </div>
        <div class="loginDeep">Deep customization</div>
        <div class="loginDeepTitle">深度定制</div>
        <div class="loginDeepCardBody">
          <div class="loginDeepCard" v-for="item in cardList" :key="item.id">
            <div class="loginDeepCircular">
              <div class="loginCircular">
                <img :src="item.url" class="loginCircularImg">
              </div>
            </div>
            <div class="loginDeepRect">
              <div class="loginDeepRectTop">{{item.name}}</div>
              <div class="loginDeepRectText">{{item.conter}}</div>
            </div>
          </div>
        </div>
        <div class="loginDeepFoot">
          <div class="loginDeepBtn">查看方案</div>
        </div>
      </div>
    </div>
    <div class="loginAdvant">
      <div class="loginAdvantTop">Five advantages</div>
      <div class="loginAdvantTitle">五大优势</div>
      <div class="loginAdvantConten">
        <div class="loginAdvantContenLeft">
          <img src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110113954_meng.png"
           class="loginAdvantContenLeftImg">
        </div>
        <div class="loginAdvantContenRigth">
          <div class="loginAdvantRow" v-for="i in advantList" :key="i.id">
            <div class="loginAdvantRowLeft">
              <div class="loginAdvantRowBorder"></div>
            </div>
            <div class="loginAdvantRowRigth">{{i.text}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="loginProfile">
      <div class="loginProfileTop">Company Profile</div>
      <div class="loginProfileTitle">企业简介</div>
      <div class="loginProfileRow">
        <div class="loginProfileRowLeft">{{profile}}</div>
        <div class="loginProfileRowRight">
          <img src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110142010_swf.png" 
          class="loginProfileRowRightImg">
        </div>
      </div>
      <div class="loginProfileFoot">
        <div class="loginProfileFootBtn">联系我们</div>
      </div>
    </div>
    <div class="loginServing">
      <div class="loginServingTop">Serving customers</div>
      <div class="loginServingTitle">服务客户</div>
      <div class="loginServingText">20年匠心服务全国千万家客户智慧化转型，共同见证，铸就行业口碑</div>
      <div class="loginServingCardBody">
        <div class="loginServingCard" v-for="item in ServingList" :key="item.id">
          <img :src="item.url" class="servingCardImg">
          <div class="loginServingCardText">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="loginFoot">
      <img :src="footBackImg" class="loginFootBackImg">
      <div class="loginFootBody">
        <div class="loginFootBodyCard">
          <div class="loginFootBodyCardItem" v-for="i in showList" :key="i.id"
          @mouseover="overCard(i.id)"
          @mouseout="outCard(i.id)">
            <div class="loginFootBodyCardItemTop">
              <img :src="i.urlbck" v-if="cardId == i.id" class="loginFootBodyCardItemTopImg">
              <img :src="i.url" v-else class="loginFootBodyCardItemTopImg">
            </div>
            <div class="loginFootBodyCardItemTitle">{{i.name}}</div>
            <div class="loginFootBodyCardItemText">{{i.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          id: 0,
          url: "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109144015_loginTop.png",
        },
      ],
      oneJust:
        "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109162204_left.png",
      oneBack:
        "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109163228_back.png",
      oneIndex: 0,
      towJust:
        "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109161939_middle.png",
      towBack:
        "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109163228_back.png",
      towIndex: 0,
      threeJust:
        "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109162109_rigth.png",
      threeBack:
        "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109163228_back.png",
      threeIndex: 0,
      cardList: [
        {id:0,name: 'APP 小程序开发',
        conter: '定制App,小程序开发、支持lOS/安卓双系统支持商家端/客户端双端开发',
        url: 'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110105527_setting.png'},
        {id:1,name: '公众号开发',
        conter: '微信开发、公众号开发、订阅号开发为了行业主流，轻量化微信应用，抢占10亿社交用户',
        url: 'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110105625_infor.png'},
        {id:2,name: '系统开发',
        conter: '为企业品牌打造自己的互联网生态圈，大数据可视化，旅游，医疗,养老，家政，办公，收银等开发系统',
        url: 'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110105725_kong.png'},
        {id:3,name: '软硬件开发',
        conter: '专业解决嵌入式硬件开发需求,旗下100+供应商,优质成功案例1000+.专属客服一对一服务.高效便捷,节约成本.立即匹配供应商',
        url: 'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110105814_yisi.png'}
      ],
      advantList: [
        {id:0,text: '领先的分析模型体系,快速业务创新，塑造业务发展新引擎'},
        {id:1,text: '完善的开发套件,强大的业务配置和集成开回发能力,构建生态开发环境'},
        {id:2,text: '低代码快速开发.低成本交付，缩短开发周期 '},
        {id:3,text: '标准模块。快速应用、多模块选择、自由组合、个性定制'},
        {id:4,text: '构建企业决策大脑，形成企业数据资产,赋能前端业务'},
      ],
      profile: '安徽英制马力信息技术有限公司是一家致力于扎根行业、深入用户,为政府、企业提供智慧养老、智慧信访、智慧旅游、网络信息安全解决方案的技术服务型企业。成立于2018年,凭借多年来对用户需求的潜心研究与技术创新,研发出基于拥有完全自主知识产权的“智慧信访”、“智慧社区及家庭养老”“智慧旅游”的三大区域链产品,并已获得多项发明专利及软件著作权。是国家高新技术企业、安徽省双软企业，安徽省中小型科技企业、合肥市大数据企业、合肥市高新区雏鹰企业。经过多年的耕耘,该公司是一支“追求专业、享受专注，体现价值、不断创新”,致力打造科技+数据结合方式,实现多维度全方位标准化为核心的信息化企业。',
      ServingList: [
        {id:0,name: '大别山中小江南智慧文旅',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110143740_tu1.png'},
        {id:1,name: '司空山电子票务',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144346_tu2.png'},
        {id:2,name: '绍兴景区手绘地图',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144518_tu3.png'},
        {id:3,name: '乡村助农定制小程序',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144552_tu4.png'},
        {id:4,name: '冶溪镇智慧养老项目',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144629_tu5.png'},
        {id:5,name: '仪家智慧养老项目',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144705_tu6.png'},
        {id:6,name: '柏霖机构智慧养老项目',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144745_tu7.png'},
        {id:7,name: '冶溪智慧信访项目',url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110144822_tu8.png'},
      ],
      footBackImg: 'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110150853_bckimg.png',
      showList: [
        {id:0,name:'专业的策划团队',text:'策划是制作软件的关键内容之一策划的好与坏，直接影响软件的可行性和推广性，专业的策划不仅是制作成功的重要条件之一，也是网站形成的先行条件。',
        url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110153718_btn1.png',
        urlbck:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110161007_btn_1.png'},
        {id:1,name:'无忧的售后',text:'有专门的技术售后服务团队，7*24小时陪伴式客户成功服务体系，系统bug快速应解，扫除应用障碍。',
        url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110153754_btn2.png',
        urlbck:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110161024_btn_2.png'},
        {id:2,name:'丰富的经验',text:'公司团队经验丰富，平均五年以上，每位技术人员都负责过很多大型项目。可以独立根据客户需求快速给出方案。产品均为自主研发，确保了产品安全和稳定性。',
        url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110154537_btn3.png',
        urlbck:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110160435_btn_3.png'},
        {id:3,name:'一站式解决方案',text:'硬件+平台+APP一整智慧康养解决方案，一步到位解决客户需求。',
        url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110154358_btn4.png',
        urlbck:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110161042_btn_4.png'},
      ],
      cardId: '',
      menuBtn:[
        {id:0,name:'首页',list:[
          {id:11,title:'产品介绍'},
          {id:12,title:'企业介绍'},
          {id:13,title:'智慧信访'}
        ]},
        {id:1,name:'产品中心',list:[
          {id:14,title:'智慧旅游'},
          {id:15,title:'智慧康养'},
          {id:16,title:'智慧信访'}
        ]},
        {id:2,name:'解决方案',list:[]},
        {id:3,name:'智慧商城',list:[]},
        {id:4,name:'关于我们',list:[
          {id:17,title:'公司介绍'},
          {id:18,title:'公司资质'},
          {id:19,title:'发展历程'}
        ]},
      ]
    };
  },
  created() {},
  methods: {
    menuClick(s) {
      this.menuId = s.id;
    },
    overOne(s) {
      this.oneIndex = s;
    },
    outOne(s) {
      this.oneIndex = s;
    },
    overTow(s) {
      this.towIndex = s;
    },
    outTow(s) {
      this.towIndex = s;
    },
    overThree(s) {
      this.threeIndex = s;
    },
    outThree(s) {
      this.threeIndex = s;
    },
    overCard(i){
      this.cardId = i
    },
    outCard(i){
      this.cardId = parseInt('-' + i)
    }
  },
};
</script>

<style>
.loginMind {
  width: 100%;
  height: 100%;
}

.carouselTop {
  width: 100%;
  height: 800px;
}

.el-carousel__container {
  width: 100%;
  height: 760px;
}

.carouselImg {
  width: 100%;
  height: 100%;
}

.loginCore {
  width: 100%;
  height: 800px;
  background-color: #f5f7f9;
  padding: 40px 0px;
  box-sizing: border-box;
}

.loginCoreRow {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ebebeb;
  font-size: 40px;
}

.loginCoreTitle {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #033333;
  font-size: 46px;
}

.loginCorePhoto {
  width: 100%;
  height: 460px;
  display: flex;
  justify-content: center;
}

.loginCorePhotoItem {
  min-width: 384px;
  height: 100%;
}

.loginCorePhotoItemImg {
  width: 384px;
  height: 100%;
}

.loginCorePhotoActive {
  width: 454px;
  height: 100%;
}

.loginCorePhotoMiddle {
  min-width: 384px;
  height: 100%;
  margin: 0px 15px;
}

.loginSolve {
  position: relative;
  width: 100%;
  height: 1717px;
}

.solvebackgroundImg {
  width: 100%;
  height: 100%;
}

.loginSolveBody {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 40px 0px;
  box-sizing: border-box;
}

.loginSolvetop {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #6c84ab;
  font-size: 40px;
}

.loginSolveTitle {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #3d7eff;
  font-size: 46px;
}

.loginSolveMiddle{
    width: 100%;
    height: 400px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginSolveMiddleLeft{
    width: 270px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginSolveMiddleRow{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loginSolveMiddleRowOne{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginSolveMiddleRowTow{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loginSolveMiddleRowThree{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.loginSolveMiddleRowFour{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginSolveMiddleRowFive{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.loginSolveMiddleItem{
  width: 185px;
  height: 57px;
  background-color: #D5DEED;
  text-align: center;
  line-height: 57px;
  font-size: 20px;
}

.loginSolveMiddleImg{
    width: 372px;
    height: 394px;
}

.loginDeep{
  width: 100%;
  height: 80px;
  margin-top: 200px;
  color: #6C84AB;
  text-align: center;
  line-height: 80px;
  font-size: 40px;
}

.loginDeepTitle{
  width: 100%;
  height: 80px;
  color: #3D7EFF;
  text-align: center;
  line-height: 80px;
  font-size: 46px;
}

.loginDeepCardBody{
  width: 100%;
  height: 378px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.loginDeepCard{
  position: relative;
  width: 263px;
  height: 378px;
  margin: 0px 50px;
  display: flex;
  align-items: flex-end;
}

.loginDeepCircular{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 104px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loginCircular{
  width: 104px;
  height: 104px;
  border-radius: 52px;
  background-color: #1C3770;
  box-shadow: 0px 3px 6px #2F4F92;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginCircularImg{
  width: 44px;
  height: 44px;
}

.loginDeepRect{
  width: 263px;
  height: 331px;
  background-image: linear-gradient(to bottom, #3F72DC, #0E1F40);
  color: #E0E9F8;
  padding: 40px 30px;
  box-sizing: border-box;
}

.loginDeepRectTop{
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
}

.loginDeepRectText{
  width: 100%;
  height: calc(100% - 80px - 80px);
  font-size: 16px;
}

.loginDeepFoot{
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginDeepBtn{
  width: 220px;
  height: 60px;
  border-radius: 37px;
  border: 1px solid #00DBFF;
  color: #3D7EFF;
  font-size: 16px;
  text-align: center;
  line-height: 60px;
}

.loginDeepBtn:hover{
  cursor: pointer;
}

.loginAdvant{
  width: 100%;
  height: 820px;
  background-color: #f5f7f9;
  padding: 40px 0px;
  box-sizing: border-box;
}

.loginAdvantTop{
  width: 100%;
  height: 80px;
  color: #EBEBEB;
  font-size: 40px;
  text-align: center;
  line-height: 80px;
}

.loginAdvantTitle{
  width: 100%;
  height: 80px;
  color: #033333;
  font-size: 46px;
  text-align: center;
  line-height: 80px;
}

.loginAdvantConten{
  width: 100%;
  height: 350px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.loginAdvantContenLeft{
  width: 533px;
  height: 100%;
  margin-right: 80px;
}

.loginAdvantContenLeftImg{
  width: 100%;
  height: 100%;
}

.loginAdvantContenRigth{
  width: 540px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.loginAdvantRow{
  width: 100%;
  height: 60px;
  display: flex;
}

.loginAdvantRowLeft{
  width: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginAdvantRowBorder{
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3D7EFF;
}

.loginAdvantRowRigth{
  width: calc(100% - 30px);
  height: 60px;
  color: #033333;
  font-size: 16px;
  line-height: 60px;
}

.loginProfile{
  width: 100%;
  height: 860px;
  background-color: #f5f7f9;
  padding: 40px 0px;
  box-sizing: border-box;
}

.loginProfileTop{
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #EBEBEB;
  font-size: 40px;
}

.loginProfileTitle{
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #033333;
  font-size: 46px;
}

.loginProfileRow{
  width: 100%;
  height: 350px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.loginProfileRowLeft{
  width: 518px;
  height: 100%;
  margin-right: 120px;
  color: #033333;
  font-size: 16px;
  letter-spacing: 8px;
}

.loginProfileRowRight{
  width: 533px;
  height: 350px;
}

.loginProfileRowRightImg{
  width: 100%;
  height: 100%;
}

.loginProfileFoot{
  width: 100%;
  height: 80px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginProfileFootBtn{
  width: 220px;
  height: 60px;
  border-radius: 37px;
  border: 1px solid #333333;
  color: #3D7EFF;
  font-size: 16px;
  text-align: center;
  line-height: 60px;
}

.loginProfileFootBtn:hover{
  cursor: pointer;
}

.loginServing{
  width: 100%;
  height: 860px;
  background-color: #f5f7f9;
  padding: 40px 0px;
  box-sizing: border-box;
}

.loginServingTop{
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 40px;
  color: #EBEBEB;
}

.loginServingTitle{
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 46px;
  color: #033333;
}

.loginServingText{
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #707070;
  font-size: 16px;
}

.loginServingCardBody{
  width: 100%;
  height: 440px;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px 10%;
  box-sizing: border-box;
}

.loginServingCard{
  position: relative;
  width: 280px;
  height: 200px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.servingCardImg{
  width: 100%;
  height: 100%;
}

.loginServingCardText{
  position: absolute;
  bottom: 25px;
  left: 0px;
  z-index: 2;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
}

.loginFoot{
  position: relative;
  width: 100%;
  height: 800px;
  box-sizing: border-box;
}

.loginFootBackImg{
  width: 100%;
  height: 100%;
}

.loginFootBody{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 0px 356px;
  box-sizing: border-box;
}

.loginFootBodyCard{
  width: 100%;
  height: 100%;
  display: flex;
}

.loginFootBodyCard > div:first-child{
  border-left: 1px solid white;
}

.loginFootBodyCard > div:last-child{
  border-right: 1px solid white;
}

.loginFootBodyCardItem{
  width: 300px;
  height: 100%;
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding-top: 200px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
}

.loginFootBodyCardItem:hover{
 background-color:rgba(0, 0, 0, 0.2);
}

.loginFootBodyCardItemTop{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginFootBodyCardItemTopImg{
  width: 80px;
  height: 80px;
}

.loginFootBodyCardItemTitle{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 20px;
}

.loginFootBodyCardItemText{
  width: 100%;
  height: calc(100% - 40px - 80px -220px);
  padding-top:20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
</style>